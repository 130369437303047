import React from 'react'
import { useNavigate,} from 'react-router-dom';
import Tabstext from './tabstext';
import Introtext from './introtext';
import { Typography, Button } from '@mui/material';
import './styles/index.scss';


function PublicPromo(props) {
    const navigate = useNavigate();
    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';
    return (
            <div style={{ display: 'flex' }} dir={lang_dir}>
                <div className='paleYellowBg' style={{display: 'flex',  width: '50%', padding:'40px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant='h3' dir={lang_dir} >
                        {Introtext.SolfyForStudents[props.lang]}
                    </Typography>
                    <div className='imageDiv' style={{ width: '300px', height: '400px', margin:'20px 0',}}>
                        <img src="/static/art/PracticingChild_rami.jpg" height='100%' />
                    </div>
                    <Typography variant='body1' style={{ width: '400px', height: '120px', textAlign: 'center' }} dir={lang_dir} gutterBottom >
                        {Introtext.StudentPromo[props.lang]}
                    </Typography>
                    <Button variant='contained' size="large" className='solfyBtn solfyBtnThird'
                        onClick={() => navigate(`/register`)}
                        >
                        {Tabstext.GetStartedTab[props.lang]}
                    </Button>
                </div>
                <div style={{display: 'flex',  width: '50%', padding:'40px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant='h3' dir={lang_dir} >
                        {Introtext.SolfyForTeachers[props.lang]}
                    </Typography>
                    <div className='imageDiv'  style={{width: '395px', height: '400px', margin:'20px 0',}}>
                        <img src="/static/art/teacherAndPupils_cropped.jpg" height='100%' />
                    </div>
                    <Typography variant='body1' style={{ width: '500px', height: '120px', textAlign: 'center' }} dir={lang_dir} gutterBottom >
                        {Introtext.TeacherPromo[props.lang]}
                    </Typography>
                    <Button variant='contained' size="large" className='solfyBtn solfyBtnSecondary'
                        onClick={() => navigate(`/register`)}
                        >
                        {Tabstext.GetStartedTeacher[props.lang]}
                    </Button>
                </div>
            </div>

    )
}


export default PublicPromo;
