import React from 'react'
import { useState, useEffect } from "react";
import { axiosInstance, } from './axios_instance';
import Tabstext from './tabstext';
import Histogram from './histogram'
import { ContainedButton, MediumArrowDropDownIcon, AudioMenuItem, LinkButton} from './customized_components';
import { Paper, Typography, Menu, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { ReactComponent as StudentsIcon } from '/static/icons/students.svg';


function TeachetDashboard(props) {
    const [myGroupsStatistics, setMyGroupsStatistics] = useState(null);
    const [hist, setHist] = useState(null);
    const [binEdges, setBinEdges] = useState(null);
    const [histTitle, setHistTitle] = useState(null)
    const [titles, setTitles] = useState(null)
    const [TopUsers, setTopUsers] = useState(null);
    const [TopFailures, setTopFailures] = useState(null);
    const [AudioErrors, setAudioErrors] = useState(null);
    const [ActiveGroups, setActiveGroups] = useState(null);
    const [ProgressGroups, setProgressGroups] = useState(null);

    const daysOptions = [7,30,90,180,365]
    const [pastdays, setPastdays] = useState(daysOptions[1])
    const [noGroups, setNoGroups] = useState(null);

    useEffect(() => {
        if (pastdays) {
            axiosInstance.get('/api/my_groups_statistics/', {'params':{'pastdays':pastdays}}).then((response) => {
                setBinEdges(response.data.histogram_bins);
                setMyGroupsStatistics(response.data.MyGroupsStatistics);
                if (response.data.MyGroupsStatistics) {
                    setHist(response.data.MyGroupsStatistics['All Groups']);
                    setHistTitle(Tabstext.AllYourGroups[props.lang]);
                    setTitles(translateHistTitles(response.data.MyGroupsStatistics));
                    setNoGroups(false);
                } else {
                    setNoGroups(true);
                }
                setTopUsers(response.data.top_performers)
                setTopFailures(response.data.top_failures)
                setAudioErrors(response.data.top_audio_errors)
                setActiveGroups(response.data.top_active_groups)
                setProgressGroups(response.data.top_progress_groups)
            });
        }
    }, [pastdays]);

    useEffect(() => {
        if (myGroupsStatistics)
            setTitles(translateHistTitles(myGroupsStatistics));
    }, [props.lang]);

    const translateHistTitles = (dict) => {
        var histTitles = [];
        var title;
        for (let key of Object.keys(dict)) {
            if (key=='All Groups')
                title = Tabstext.AllYourGroups[props.lang];
            else if (/^\d+$/.test(key))
                title = Tabstext.GroupsOfYear[props.lang] + key;
            else
                title = key.length <= 18 ? key : '...'+key.slice(-18);
            histTitles.push(title)
        }
        return histTitles;
    };

    const [anchorHistEl, setAnchorHistEl] = useState(null);
    const histMenuOpen = Boolean(anchorHistEl);
    const handleHistogramClick = (event) => {
        setAnchorHistEl(event.currentTarget);
    };
    const handleHistClose = () => {
        setAnchorHistEl(null);
    };
    const handleHistMenuClick = (group,index) => {
        setHist(myGroupsStatistics[group])
        setHistTitle(titles[index])
        handleHistClose();
    };

    const [anchors, setAnchors] = useState([null,null,null]);
    const [openStates, setOpenStates] = useState([false,false,false]);

    const handleClick = (index, event) => {
        const newAnchors = [...anchors];
        newAnchors[index] = event.currentTarget;
        setAnchors(newAnchors);
        const newOpenStates = [...openStates];
        newOpenStates[index] = !openStates[index]; // Toggle the open state
        setOpenStates(newOpenStates);
    };

    const handleClose = (index) => {
        const newOpenStates = [...openStates];
        newOpenStates[index] = false;
        setOpenStates(newOpenStates);
    };

    const handleMenuClick = (val_index,menu_index) => {
        setPastdays(daysOptions[val_index])
        handleClose(menu_index);
    };

    const handleStudentSelect = (user) => {
        props.setContentCallback('myprogress',user)
    };

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';
    const lang_dir2 = props.lang!='he' ? 'right' : 'left';

    if (noGroups==null)
        return null

    return (
      <>
      {!noGroups &&
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '40px', margin:'40px'  }}>
            <div style={{ flex: 1, minWidth: 0 }}>
                <Paper className='longPaper' style={{height:'200px'}} >
                    <LinkButton style={{color:'black'}} onClick={handleHistogramClick} endIcon=<MediumArrowDropDownIcon /> >
                        <div dir={lang_dir} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', margin:'0 8px'}}>
                            {Tabstext.ScoresHistogram[props.lang] + histTitle}
                        </div>
                    </LinkButton>
                    <Menu anchorEl={anchorHistEl} open={histMenuOpen} onClose={handleHistClose} >
                        {Object.keys(myGroupsStatistics).map((group, index) => (
                            <AudioMenuItem key = {index} onClick={() => handleHistMenuClick(group,index)} >
                                {group}
                            </AudioMenuItem>
                        ))}
                    </Menu>
                    <Histogram hist={hist} binEdges={binEdges} lang={props.lang} />
                </Paper>
            </div>

            <div style={{ flex: 1, minWidth: 0 }}>
                <Paper className='longPaper' style={{ height: '200px' }} >
                    <LinkButton style={{ color: 'black' }} onClick={(event) => handleClick(1, event)} endIcon=<MediumArrowDropDownIcon /> >
                        <div dir={lang_dir} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', margin:'0 8px'}}>
                            {Tabstext.TopPerformers[props.lang] + pastdays.toString() + Tabstext.Days[props.lang]}
                        </div>
                    </LinkButton>
                    <Menu anchorEl={anchors[1]} open={openStates[1]} onClose={(event) => handleClose(1, event)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: lang_dir2, }}  >
                        {daysOptions.map((val, index) => (
                            <AudioMenuItem key = {index} onClick={() => handleMenuClick(index,1)} >
                                {val}
                            </AudioMenuItem>
                        ))}
                    </Menu>
                    <TopPerformersTable TopUsers={TopUsers} lang={props.lang} />
                </Paper>
            </div>

            <div style={{ flex: 1, minWidth: 0 }}>
                <Paper className='longPaper' style={{ height: '200px' }} >
                    <LinkButton style={{ color: 'black' }} onClick={(event) => handleClick(2, event)} endIcon=<MediumArrowDropDownIcon /> >
                        <div dir={lang_dir} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', margin:'0 8px'}}>
                            {Tabstext.TopGroups[props.lang] + pastdays.toString() + Tabstext.Days[props.lang]}
                        </div>
                    </LinkButton>
                    <Menu anchorEl={anchors[2]} open={openStates[2]} onClose={(event) => handleClose(2, event)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: lang_dir2, }}  >
                        {daysOptions.map((val, index) => (
                            <AudioMenuItem key = {index} onClick={() => handleMenuClick(index,2)} >
                                {val}
                            </AudioMenuItem>
                        ))}
                    </Menu>
                    <TopGroupsTable ActiveGroups={ActiveGroups} ProgressGroups={ProgressGroups} lang={props.lang} />
                </Paper>
            </div>

            <div style={{ flex: 1, minWidth: 0 }}>
                <Paper className='longPaper' style={{ height: '200px' }} >
                    <LinkButton style={{ color: 'black' }} onClick={(event) => handleClick(0, event)} endIcon=<MediumArrowDropDownIcon /> >
                        <div dir={lang_dir} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', margin:'0 8px'}}>
                            {Tabstext.Attention[props.lang] + pastdays.toString() + Tabstext.Days[props.lang]}
                        </div>
                    </LinkButton>
                    <Menu anchorEl={anchors[0]} open={openStates[0]} onClose={(event) => handleClose(0, event)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: lang_dir2, }}  >
                        {daysOptions.map((val, index) => (
                            <AudioMenuItem key = {index} onClick={() => handleMenuClick(index,0)} >
                                {val}
                            </AudioMenuItem>
                        ))}
                    </Menu>
                    <AttentionTable TopFailures={TopFailures} AudioErrors={AudioErrors} lang={props.lang} />
                </Paper>
            </div>

        </div>
      }
      {noGroups &&
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: '40px', margin:'40px'  }}>
            <div style={{ flex: 1, minWidth: 0 }}>
                <Paper className='longPaper' style={{ height: '200px' }} >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px' }} >
                        <StudentsIcon style={{ marginBottom: '40px' }} />
                        <ContainedButton onClick={() => props.setContentCallback('mystudents')} >
                            <div style={{}}> {Tabstext.CreateGroupsTab[props.lang]} </div>
                        </ContainedButton>
                    </div>
                </Paper>
            </div>
        </div>
      }
      </>
    );
}

function TopPerformersTable({ TopUsers, lang }) {
    return (
        <Table style={{borderTop: '1px solid rgba(0,0,0,.1)', }} >
            <TableBody>
            {Object.entries(TopUsers).map(([user,progress], index) => (
                <TableRow key={index}>
                    <TableCell  className='smallCell' >
                        <Typography className='orangeText' >{user}</Typography>
                    </TableCell>
                    <TableCell  className='smallCell' >
                        <Typography >{progress} {Tabstext.PassedExercises[lang]}</Typography>
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    )
}


function TopGroupsTable({ActiveGroups, ProgressGroups, lang }) {
    return (
        <Table style={{ borderTop: '1px solid rgba(0,0,0,.1)', }} >
            <TableBody>
            {Object.entries(ActiveGroups).map(([group,active], index) => (
                <TableRow key={index}>
                    <TableCell  className='smallCell' >
                        <Typography className='orangeText' >{group}</Typography>
                    </TableCell>
                    <TableCell  className='smallCell' >
                        <Typography >{active} {Tabstext.ActiveUsers[lang]}</Typography>
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
            <TableBody>
            {Object.entries(ProgressGroups).map(([group,progress], index) => (
                <TableRow key={index}>
                    <TableCell  className='smallCell' >
                        <Typography className='orangeText' >{group}</Typography>
                    </TableCell>
                    <TableCell  className='smallCell' >
                        <Typography >{progress} {Tabstext.PassedExercises[lang]}</Typography>
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    )
}


function AttentionTable({TopFailures, AudioErrors, lang }) {
    return (
        <Table style={{ borderTop: '1px solid rgba(0,0,0,.1)', }} >
            <TableBody>
            {Object.entries(TopFailures).map(([user,failures], index) => (
                <TableRow key={index}>
                    <TableCell  className='smallCell' >
                        <Typography className='orangeText' >{user}</Typography>
                    </TableCell>
                    <TableCell  className='smallCell' >
                        <Typography >{failures} {Tabstext.NotPassedRecordings[lang]}</Typography>
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
            <TableBody>
            {Object.entries(AudioErrors).map(([user,errors], index) => (
                <TableRow key={index}>
                    <TableCell  className='smallCell' >
                        <Typography className='orangeText' >{user}</Typography>
                    </TableCell>
                    <TableCell  className='smallCell' >
                        <Typography >{errors} {Tabstext.BadRecordings[lang]}</Typography>
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    )
}


export default TeachetDashboard;

//                        <LinkButton onClick={() => handleStudentSelect(Users[0])} >
//                            <Typography style={{marginLeft:'0', fontSize:'20px'}}> {Users[0]} </Typography>
//                        </LinkButton>
