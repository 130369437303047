import React, { useEffect, useState } from 'react';
import Level from './Level';
import './styles/puzzle.css';
import { fragments } from './fragments';  // Import fragment file names

function Puzzle(props) {
  const [levels, setLevels] = useState([]);
  const [unlockedLevels, setUnlockedLevels] = useState([0]); // Only the first level is unlocked
  const [solvedLevels, setSolvedLevels] = useState([]); // Track solved levels

  useEffect(() => {
    // Organize fragments into levels and set state
    const levelsData = organizeFragments(fragments);
    setLevels(levelsData);
  }, []);

  // Organize fragments into levels (x), but only load them into the stash
  const organizeFragments = (fragments) => {
    const levels = {};

    fragments.forEach((frag) => {
      const match = frag.match(/frag_(\d+)_(\d+)_(\d+)\.png/);
      if (match) {
        const [_, x, y] = match.map(Number); // Extract x (level) and y (row) from file name

        if (!levels[x]) levels[x] = { level: x, stash: [], rows: {} };

        if (!levels[x].rows[y]) levels[x].rows[y] = { row: y, fragments: [] };

        // Store fragments only in the stash
        levels[x].stash.push(frag);
      }
    });

    // Convert the object into arrays and return the result
    return Object.values(levels).map((level) => ({
      ...level,
      rows: Object.values(level.rows),  // Store rows in an array for dynamic rendering
    }));
  };

  // Function to handle level completion
  const handleLevelSolved = (levelIndex) => {
    setSolvedLevels([...solvedLevels, levelIndex]);

    const nextLevel = levelIndex + 1;
    if (nextLevel < levels.length) {
      setUnlockedLevels([...unlockedLevels, nextLevel]); // Unlock next level
    }
  };

  return (
    <div className="App">
      <h1>Musical Puzzle</h1>
      {levels.map((level, index) => (
        unlockedLevels.includes(index) && ( // Render only if the level is unlocked
        <Level
            test={props.test }
            key={level.level}
            level={level}
            isUnlocked={unlockedLevels.includes(index)} // Only render unlocked levels
            isSolved={solvedLevels.includes(index)} // Lock level if solved
            onLevelSolved={() => handleLevelSolved(index)} // Mark the level as solved
          />
        )
      ))}
    </div>
  );
}

export default Puzzle;
