import React, { useState, useEffect } from "react";
import { MenuItem, Typography, Button, Tooltip, Select } from '@mui/material';
import './styles/extras.css';
import ExerciseDescriptions from './exercise_descriptions';
import FeedbackMessage from './feedback_message';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { CustomTypography } from './styled_components'

function TitleAndSongSelections(props) {
    const context = props.context;
    const lang = props.lang;

    const [exercises, setSongExercises] = useState(null);
    const [scales, setScales] = useState(null);
    const [voices, setVoices] = useState(null);
    const [segments, setSegments] = useState(null);
    const [scaleInd, setScaleInd] = useState(null);
    const [voiceInd, setVoiceInd] = useState(null);
    const [segmentInd, setSegmentInd] = useState(null);


    useEffect(() => {
        if (context.song_exercises) {
            setSongExercises(context.song_exercises);
            setScales(context.song_scales);
            setVoices(context.song_voices);
            setSegments(context.song_segments);
            const indexes = findIndexes(context.song_exercises, props.exe.pk);
            if (indexes) {
                setScaleInd(indexes[0]);
                setVoiceInd(indexes[1]);
                setSegmentInd(indexes[2]);
            }
        }
    }, [context.song_exercises, props.exe.pk]);

    const handleScaleChange = (e) => {
        const ind = parseInt(e.target.value, 10);
        props.setPkCallback(exercises[ind][voiceInd][segmentInd]);
        setScaleInd(ind);
    };
    const handleVoiceChange = (e) => {
        const ind = parseInt(e.target.value, 10);
        props.setPkCallback(exercises[scaleInd][ind][segmentInd]);
        setVoiceInd(ind);
    };
    const handleSegmentChange = (e) => {
        const ind = parseInt(e.target.value, 10);
        props.setPkCallback(exercises[scaleInd][voiceInd][ind]);
        setSegmentInd(ind);
    };

    const TooltipTitle = ExerciseDescriptions[lang][props.exe.descriptor];

    if (!props.feedbackDisplay && context.song_exercises && (scaleInd === null || voiceInd === null || segmentInd === null || exercises === null))
        return null;

    var singleVoice = null;
    if (voices) {
        singleVoice = voices.length == 1 || exercises[scaleInd].every(row => row[segmentInd] === exercises[scaleInd][0][segmentInd]);
    }

    return (
        <div style={{display: 'flex', justifyContent: 'space-between', width:'100%', }} >
            <PrevExercise context={context}  lang={lang}
                prevExe={props.prevExe} prevEnabled={props.prevEnabled}
                setPkCallback={props.setPkCallback}
                />
            <div style={{display: 'flex', gap:'20px', alignItems:'center'}}>
                {context.num_song_exercises > 0 && props.exerciseView !== 'lyrics' && scales[0] != '' &&
                    <>
                    {!props.feedbackDisplay &&
                        <Select
                            value={scaleInd}
                            onChange={handleScaleChange}
                            disabled={scales.length == 1}
                            variant="standard"
                            style={{ height: '32px', }}
                            >
                            {scales.map((s, index) => (
                                <MenuItem key={index} value={index}>
                                    {s}
                                </MenuItem>
                            ))}
                        </Select>
                    }
                    {(props.feedbackDisplay ) &&
                        <Typography variant='body1' style={{ marginTop: '15px' }}>
                            {scales[scaleInd]}
                        </Typography>
                    }
                    </>
                }
                <Tooltip title={TooltipTitle} sx={{paddingTop:'8px'}}>
                    <CustomTypography
                        normalstyle={{ textTransform: 'none', fontWeight: '450', fontSize: '24px' }}
                        smallstyle={{ textTransform: 'none', fontWeight: '300', fontSize: '16px' }}
                        text={context.object_title}
                        addclass={null} >                                        
                    </CustomTypography>
                </Tooltip >
                {context.num_song_exercises > 0 && props.exerciseView !== 'lyrics' &&
                    <>
                    {!singleVoice &&
                        <>
                            {!props.feedbackDisplay &&
                                <Select
                                    value={voiceInd}
                                    onChange={handleVoiceChange}
                                    disabled={voices.length == 1}
                                    variant="standard"
                                    style={{ height: '32px', }}
                                >
                                    {voices.map((v, index) => (
                                        <MenuItem key={index} value={index}>
                                            {v}
                                        </MenuItem>
                                    ))}
                                </Select>
                            }
                            {(props.feedbackDisplay) &&
                                <Typography variant='body1' style={{ marginTop: '15px' }}>
                                    {voices[voiceInd]}
                                </Typography>
                            }
                        </>
                    }
                    {segments.length > 1 &&
                        <>
                            {!props.feedbackDisplay &&
                                <Select
                                    value={segmentInd}
                                    onChange={handleSegmentChange}
                                    disabled={segments.length == 1}
                                    variant="standard"
                                    style={{ height: '32px', }}
                                    >
                                    {segments.map((seg, index) => (
                                        <MenuItem key={index} value={index}>
                                            {seg}
                                        </MenuItem>
                                    ))}
                                </Select>
                            }
                            {props.feedbackDisplay &&
                                <Typography variant='body1' style={{ marginTop: '15px' }}>
                                    {segments[segmentInd]}
                                    </Typography>
                            }
                        </>                        
                    }
                    </>
                }
            </div>
            <div style={{display: 'flex',justifyContent: 'space-between', width:'70',}}>
                {props.feedbackData && props.feedbackData.feedback_message &&
                    <FeedbackMessage
                        lang={props.lang}
                        feedbackData={props.feedbackData}
                        inClass = {props.inClass} recTest={props.recTest}
                        display={props.feedbackDisplay}
                    />
                }
                <NextExercise context={context}  lang={lang}
                    nextExe={props.nextExe} setPkCallback={props.setPkCallback}
                    nextEnabled={props.nextEnabled}
                />
            </div>
        </div>
    );
}

const findIndexes = (arr, target) => {
    for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].length; j++) {
            for (let k = 0; k < arr[i][j].length; k++) {
                if (arr[i][j][k] === target) {
                    return [i, j, k];
                }
            }
        }
    }
    return null; // Return null if the target is not found
};

function NextExercise(props) {
    const handleClick = (event) => {
        props.setPkCallback(props.nextExe.pk);
    };

    return ( props.nextExe && props.nextEnabled ? (
        <Button className='faint_bgrd'
                variant="text"
                onClick={handleClick}
                style = {{padding:'0px 4px 0px 0px', margin:'0px', minWidth:'0px', minHeight:'0px', }}
                endIcon={<ArrowRightIcon  style={{color:'black', fontSize: '32px !important', }} />}
            >
            </Button>
            )
        : <span style={{ visibility: 'hidden' }}>&nbsp;</span> )

    };
function PrevExercise(props) {
    const prevExe = props.prevExe;
    const handleClick = (event) => {
        props.setPkCallback(prevExe.pk);
    };

    return (prevExe && props.prevEnabled ? (
        <Button className='faint_bgrd'
                variant="text"
                onClick={handleClick}
                style = {{padding:'0px 8px 0px 0px', margin:'0px', minWidth:'0px', minHeight:'0px',}}
                endIcon={<ArrowLeftIcon style={{color:'black', fontSize: '32px !important', }} />}
            >
            </Button>
            )
        : <span style={{ visibility: 'hidden' }}>&nbsp;</span> )
}

export default TitleAndSongSelections
