import React, { useState, useEffect, useRef } from 'react';
import Stash from './Stash';
import Row from './Row';

function Level({ test, level, isUnlocked, isSolved, onLevelSolved }) {
    const audioRef = useRef(null); // Reference to the audio element

    const shuffleFragments = (fragments) => {
        return fragments.sort(() => Math.random() - 0.5);
    };

    const [rows, setRows] = useState(level.rows);
    const [stash, setStash] = useState(shuffleFragments(level.stash));

        // Automatically start/stop the audio based on whether the level is unlocked or solved
    useEffect(() => {
        if (test == 'audio') {
            if (isUnlocked && !isSolved && audioRef.current) { } else if (isSolved && audioRef.current) {
                audioRef.current.pause(); // Pause audio when the level is solved
            }
        }
    }, [isUnlocked, isSolved]);

    const onDropToRow = (fragment, rowIndex, insertIndex = null) => {
        if (!rows[rowIndex] || isSolved) return;

        const newStash = stash.filter((frag) => frag !== fragment);
        const newRows = rows.map((row) => ({
            ...row,
            fragments: row.fragments.filter((frag) => frag !== fragment),
        }));

        if (insertIndex !== null) {
            newRows[rowIndex].fragments.splice(insertIndex, 0, fragment); // Insert at specific index
        } else {
            newRows[rowIndex].fragments.push(fragment); // Append to the end
        }

        setRows(newRows);
        setStash(newStash);
    };

    const onDropToStash = (fragment) => {
        if (isSolved) return;

        // Ensure the fragment is not already in the stash (prevents duplication)
        const fragmentExistsInStash = stash.includes(fragment);

        // Add the fragment back to the stash only if it doesn't already exist
        if (test == 'audio' || !fragmentExistsInStash) {
            const newStash = [...stash, fragment];

            // Find and remove the fragment from the rows
            const newRows = rows.map((row) => ({
                ...row,
                fragments: row.fragments.filter((frag) => frag !== fragment),
            }));

            setRows(newRows); // Update rows
            setStash(newStash); // Update stash
        };
    };

    const checkOrder = () => {
        if (stash.length > 0) {
            alert(`Level ${level.level} Incorrect Solution: You must use all fragments.`);
            return;
        }

        let isCorrect = true;

        rows.forEach((row) => {
            row.fragments.forEach((fragment, index) => {
                const correctFragment = `frag_${level.level}_${row.row}_${index}.png`;
                if (fragment !== correctFragment) {
                    isCorrect = false;
                }
            });
        });

        if (isCorrect) {
            onLevelSolved(); // Notify parent that the level is solved
        } else {
            alert(`Level ${level.level} Incorrect Solution.`);
        }
    };

    return (
        <div className={`level ${isUnlocked ? 'visible' : ''} ${isSolved ? 'solved' : ''}`}>
            {/* Hide the title if the level is solved */}
            {!isSolved && <h2>Level {level.level}</h2>}

            {/* Audio player for the level */}
            {test == 'audio' && isUnlocked && !isSolved && (
                <audio ref={audioRef} controls>
                    <source src={`/media/games/puzzle-sound/level_${level.level}.mp3`} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            )}

            {!isSolved && <Stash stash={stash} onDropFragment={onDropToStash} />}

            <div className="rows">
                {rows.map((row, rowIndex) => (
                    <Row
                        key={rowIndex}
                        row={row}
                        onDrop={(fragment, insertIndex) => onDropToRow(fragment, rowIndex, insertIndex)}
                        isSolved={isSolved}
                    />
                ))}
            </div>

            {isUnlocked && !isSolved && <button onClick={checkOrder}>Check Order</button>}
        </div>
    );
}

export default Level;
