import React from 'react'

const Messages = {

    mute_message : {
        'en': 'Your microphone seems to be muted.',
        'he': 'נראה שהמיקרופון נמצא במצב השתקה (mute)',
        'ro': 'Microfonul dvs. pare să fie dezactivat',
    },
    snr_message : {
        'en': 'There is significant noise in the background. Listen to your recording and verify that your voice sounds loud and clear and there are no noise, music, or other sounds in the background.',
        'he': 'יש הרבה רעשי רקע. הקשב/י להקלטתך וודא/י שקולך נשמע צלול וברור ושאין רעש, מוזיקה, או קולות אחרים ברקע',
        'ro': 'Se aude mult zgomot de fundal. Ascultați înregistrarea și verificați dacă vocea sună tare și clar și că nu există zgomot, muzică sau alte sunete în fundal.',
    },
    echo_message : {
        'en': 'The audio guide may have entered into the recording and confused the feedback. It is recommended to use a headset.',
        'he': 'יתכן שהאות המוביל נכנס להקלטה ובלבל את המשוב. מומלץ להשתמש באוזניות עם מיקרופון.',
        'ro': 'Este posibil ca ghidul audio să fi intrat în înregistrare și să fi încurcat feedbackul. Se recomandă utilizarea unei căști.',
    },
    too_many_errors_message : {
        'en': 'Too many errors were found in your recording for the feedback to be reliably displayed. You possibly made those mistakes, but it is also possible that the recording was distrupted in some way. Please play your recording and verify that your voice sounds clear and without any interference in the background',
        'he': 'יותר מדי שגיאות נמצאו בהקלטה מכדי להציג את המשוב באופן אמין. יתכן שאלו שגיאות שלכם, אבל גם יתכן שההקלטה הופרעה בדרך כלשהי. אנא נגנו את הקלטתכם וודאו שקולכם נשמע ברור וללא הפרעות כלשהן ברקע',
        'ro': 'Au fost găsite prea multe erori în înregistrarea ta pentru ca feedback-ul să fie afișat în mod fiabil. Este posibil să fi făcut aceste greșeli, dar este de asemenea posibil ca înregistrarea să fi fost întreruptă într-un fel. Te rugăm să redai înregistrarea și să verifici dacă vocea ta sună clar și fără interferențe în fundal.',
    },
    too_short_to_analyze : {
        'en': 'Too short to give feedback.',
        'he': 'קצר מדי למתן משוב.',
        'ro': 'Prea scurt pentru a da feedback.',
    },
    recording_was_interrupted : {
        'en': 'To obtain credit you should not interrupt the recording.',
        'he': 'כדי שהתרגיל יחשב, אל תפסיק/י את ההקלטה באמצע.',
        'ro': 'Pentru a obține credit, nu întrerupeți înregistrarea.',
    },
    recording_test_instructions_message : {
        'en': 'Please listen to your recording until the end, then approve its quality.',
        'he': 'בבקשה הקשיבו להקלטה עד הסוף ואז אשרו שהיא באיכות טובה',
        'ro': 'Ascultați vă rog înregistrarea până la final și aprobați-i calitatea.',
    },
    low_score_message : {
        'en': 'You need 60 to pass to the next exercise. You scored',
        'he': 'נדרש ציון 60 למעבר לתרגיל הבא. השגת ציון',
        'ro': 'Ai nevoie de 60 pentru a trece la următorul exercițiu. Ai obținut',
    },
    pass_offtunehigh_message : {
        'en': 'You passed, but you are consistently singing a little too high. You scored',
        'he': 'עברת, אבל את/ה שר/ה בעקביות קצת גבוה מדי. השגת ציון',
        'ro': 'Ați reușit, dar cântați în mod constant un pic prea sus. Ai obținut',
    },
    pass_offtunelow_message : {
        'en': 'You passed, but you are consistently singing a little too low. You scored',
        'he': 'עברת, אבל את/ה שר/ה בעקביות קצת נמוך מדי.השגת ציון',
        'ro': 'Ați reușit, dar cântați în mod constant un pic prea jos. Ai obținut',
    },
    pass_message : {
        'en': 'Congratulations, you passed. You scored',
        'he': 'כל הכבוד, עברת. השגת ציון',
        'ro': 'Felicitări, ați reușit. Ai obținut',
    },
    outstanding_message : {
        'en': 'Outstanding performance! You scored',
        'he': 'ביצוע יוצא מן הכלל! השגת ציון',
        'ro': 'Performanță remarcabilă! Ai obținut',
    },
    accwin_message : {
        'en': 'Very good! You scored',
        'he': 'טוב מאוד! השגת ציון',
        'ro': 'Foarte bun! Ai obținut',
    },
    no_feedback_inclass : {
        'en': 'No feedback in class mode',
        'he': 'אין משוב במצב כיתה',
        'ro': 'Fără feedback în modul În Clasă',
    },
    no_feedback_this_exercise: {
        'en': 'No feedback for this exercise',
        'he': 'אין משוב לתרגיל זה',
        'ro': 'Fără feedback pentru acest exercițiu',
    },
   bug_message : {
        'en': "Sorry, an error has been encountered. Solfy's team has been notified. Please repeat the exercise.",
        'he': 'מצטערים, אירעה שגיאה. נשלחה הודעה לצוות סולפי. אנא חזרו על התרגיל',
        'ro': 'Ne pare rău, a apărut o eroare. Echipajul Solfy a fost sesizat. Vă rugăm să repetați exercițiul.',
    },
    bad_recording_message : {
        'en': "Sorry, there was a problem with the recording and Solfy's team has been notified. Please note that Solfy operates properly under Chrome, Edge, and Firefox only.",
        'he': "מצטערים, אירעה תקלה בהקלטה ונשלחה הודעה לצוות סולפי. לידיעתך, סולפי פועלת באופן תקין עם כרום, אדג' ופיירפוקס בלבד",
        'ro': 'Ne pare rău, a apărut o problemă cu înregistrarea și echipajul Solfy a fost sesizat. Vă rugăm să rețineți că funcționează corect numai în Chrome, Edge și Firefox.',
    },
    clickon_practice : {
        'en': 'Click on "Practice" to record again',
        'he': 'לחצ/י על "תירגול" כדי להקליט שוב',
        'ro': 'Faceți clic pe „Exersați” pentru a înregistra din nou',
    },
};

export default Messages