import React from 'react'
import { useState, useEffect } from "react";
import { axiosInstance,  } from './axios_instance';
import Tabstext from './tabstext';
import SolfegeProgressTable from './solfege_progress_table';
import SongsProgressTable from './songs_progress_table';
import { PracticeTab, PageTitle, BackArrowButton } from './customized_components';
import { Tabs, } from '@mui/material';


function StudentDetails(props) {
    const [solfegeInfo, setSolfegeInfo] = useState(null);
    const [songInfo, setSongInfo] = useState(null);
    const [loggedSolfegeLevels, setLoggedSolfegeLevels] = useState(null);

    const [state, setState] = useState(Boolean(props.lyric)?props.lyric:'solfege');
    const handleSelect = (event, val) => {
        setState(val);
    };

    useEffect(() => {
        axiosInstance.get('/api/student_details/',{'params':{'username': props.username}})
            .then((response) => {
                setSolfegeInfo(response.data.solfegeInfo);
                setSongInfo(response.data.songsInfo);
                truncateSolfegeLevels(response.data.level_ids,response.data.lesson_ids,response.data.solfege_ids);
        })
        .catch((error) => {
            console.log('get student error:', error);
            props.setPageCallback('groupDetails', { group: props.group });
        });
    }, [props.SolfegeLevels]); // end useEffect

    const truncateSolfegeLevels = (level_ids,lesson_ids,solfege_ids) => {
        var truncLevels = truncateObject(props.SolfegeLevels,level_ids);
        truncLevels = JSON.parse(JSON.stringify(truncLevels));
        for (const level of truncLevels) {
            let trunc_lesson_set = truncateObject(level.lesson_set,lesson_ids);
            level.lesson_set = trunc_lesson_set;
            for (const lesson of trunc_lesson_set) {
                let trunc_exercise_set = truncateObject(lesson.exercise_set,solfege_ids);
                lesson.exercise_set = trunc_exercise_set;
            }
        }
        setLoggedSolfegeLevels(truncLevels);
    };

    const handleExeSelect = (exe,lyric) => {
        props.setPageCallback('exerciseDetails', { exe_pk: exe.pk, lyric:lyric, username: props.username, group: props.group })
    };

    if (solfegeInfo==null || loggedSolfegeLevels==null) {
        return <div></div>;
    }

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';
    return (
        <div  style={{ overflowY: 'auto'}} >
            <div style={{ display: 'flex', alignItems:"center", marginBottom:'12px'}} >
                {props.group &&
                    <BackToGroup lang={props.lang} setPageCallback={props.setPageCallback} group={props.group} />
                }
                <PageTitle style={{flex: 1, textAlign: 'center'}} >
                    {props.username}
                </PageTitle>
            </div>
            <Tabs
                orientation="horizontal"
                value={state}
                onChange={handleSelect}
                indicatorColor='transparent'
                style={{ minHeight:0, marginLeft:'18px'}}
            >
                <PracticeTab
                    label={Tabstext.SolfegeTab[props.lang]}
                    value={'solfege'}
                    />
                <PracticeTab
                    label={Tabstext.SongsTab[props.lang]}
                    value={'songs'}
                    />
            </Tabs>
            <div className='tableWrapper' style={{borderRadius: '0 16px 16px 16px', margin:'0 18px 18px 18px'  }}>
                <SolfegeProgressTable
                    lang={props.lang} windowHeight={props.windowHeight}
                    SolfegeInfo={solfegeInfo} handleExeSelect={handleExeSelect}
                    loggedSolfegeLevels={loggedSolfegeLevels}
                    teacherReview = {props.teacherReview}
                    group={props.group} display={state=='solfege'?'':'none'}
                />
                <SongsProgressTable
                    lang={props.lang} windowHeight={props.windowHeight}
                    songInfo={songInfo} handleExeSelect={handleExeSelect}
                    teacherReview = {props.teacherReview}
                    group={props.group} display={state=='songs'?'':'none'}
                />
            </div>
        </div>

    );
}

function BackToGroup(props) {
    const handleButtonClick = () => {
        props.setPageCallback('groupDetails',{ group: props.group });
    };
    return  (
        <BackArrowButton onClick={handleButtonClick} >
            {props.group} 
        </BackArrowButton>
    )
}

export default StudentDetails;

function truncateObject(originalObject, target_ids) {
    const truncatedObject = [];
    for (let key in originalObject) {
        if (target_ids.includes(originalObject[key].pk)) {
            truncatedObject.push(originalObject[key]);
        }
    }
    return truncatedObject;
}