// Export an array of fragment file names
export const fragments = [
        'frag_1_1_0.png',
        'frag_1_1_1.png',
        'frag_1_1_2.png',
        'frag_1_1_3.png',

        'frag_2_2_0.png',
        'frag_2_2_1.png',
        'frag_2_2_2.png',
        'frag_2_2_3.png',

        'frag_3_3_0.png',
        'frag_3_3_1.png',
        'frag_3_3_2.png',
        'frag_3_3_3.png',
        'frag_3_4_0.png',
        'frag_3_4_1.png',
        'frag_3_4_2.png',
        'frag_3_4_3.png',
];
