import React, { useState, useEffect, useRef,  } from "react";
import { axiosInstance, } from './axios_instance';
import PracticeRecordingsSelect from './practice_recordings_select';
import ScorePage from './rectest_score_page';
import Tabstext from './tabstext';
import {PutSetFeedbackCallback} from './exercise_utils';
import Checkbox from '@mui/material/Checkbox';
import { Popover, Typography, FormControlLabel } from '@mui/material';
import { ExerciseContainer, ForwardArrowButton, ContainedButton, ContainedButton2, ContainedButton3 } from './customized_components';

function RecordingTest(props) {
    const anchorRef = useRef(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const handlePlaybackEndCallback = () => {
        setAnchorEl2(anchorRef.current);
    };

    const handleVerify = (val) => {
        if (val==1) {
            setAnchorEl2(null);
            props.resetViewCallback(1);
        }
        else if (val==0){
            setAnchorEl2(null);
            setFeedbackDisplayCallback(false);
        }
        else { // val==-1
            setAnchorEl2(null);
        }
    }
    const [context, setContext] = useState({});
    const [exe, setExe] = useState(null);
    const [musicScore, setMusicScore] = useState(null);
    const [feedbackData, setFeedbackData] = useState(null);

    // invoked from exercise_utils
    const [feedbackDisplay, setFeedbackDisplay] = useState(false);
    const setFeedbackDisplayCallback = (val, data) => {
        if (val && (data || feedbackData)) {
            setFeedbackDisplay(val);
            if (data) {
                setFeedbackData(data);
                setMusicScore(data.score);
            } else {
                setMusicScore(feedbackData.score);
            }
        }
        else {
            setFeedbackDisplay(false);
            setMusicScore(exe.score);
        }
    };
    PutSetFeedbackCallback(setFeedbackDisplayCallback);     // invoked from exercise_utils

    useEffect(() => {
        axiosInstance.get('/api/exercise/',{'params':{'pk':'recording_test', 'studentUser':null}})
            .then((response) => {
                if (response.data.context) {
                    response.data.context.object_title=Tabstext.RecordingTest[props.lang];
                    setContext(response.data.context);
                    setExe(response.data.exe);
                    setMusicScore(response.data.exe.score);
                    setFeedbackDisplay(false);
                    setFeedbackData(null);
                }
        });
    }, []);

    useEffect(() => {
        setContext(context => ({
            ...context,
            ['object_title']: Tabstext.RecordingTest[props.lang]
        }));
    }, [props.lang]);

    const [checkedState, setCheckedState] = useState([false, false, false]); // Initial state for all checkboxes
    const [okEnabled, setOkEnabled] = useState(false);
    const handleCheckboxChange = (event, index) => {
        const updatedCheckedState = [...checkedState];
        updatedCheckedState[index] = event.target.checked;
        setCheckedState(updatedCheckedState);
        if (checkedState[0]==true && checkedState[1]==true && checkedState[2]==true)
            setOkEnabled(true)
    };
    useEffect(() => {
        if (checkedState[0]==true && checkedState[1]==true && checkedState[2]==true)
            setOkEnabled(true);
        else
            setOkEnabled(false);
    }, [checkedState]);

    if (context==null || Object.keys(context).length === 0 || exe==null) {
        return (
        <ExerciseContainer >
        </ExerciseContainer>
        );
    }

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';

    return (
        <ExerciseContainer ref={anchorRef}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <PracticeRecordingsSelect context={context} lang={props.lang}
                    feedbackDisplay={feedbackDisplay} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                />
                <ForwardArrowButton onClick={() => props.resetViewCallback()} >
                    {Tabstext.CancelTab[props.lang]}
                </ForwardArrowButton>
            </div>
            <Popover
                open={Boolean(anchorEl2)}
                anchorEl={anchorEl2}
                anchorOrigin={{vertical: 'top', horizontal: 'center', }}
                transformOrigin={{vertical: 'top', horizontal: 'center', }}
            >
                <div className='popOver' style={{ padding: '10px' }} >
                    <Typography variant='body1' dir={lang_dir} gutterBottom >
                        {Tabstext.RecordingTestInstructions2[props.lang]}
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column',  }}>
                        <FormControlLabel
                            control={<Checkbox checked={checkedState[0]} onChange={(event) => handleCheckboxChange(event, 0)}
                                sx={{'& .MuiSvgIcon-root': { fontSize: 20 }}}  />}
                            label={Tabstext.recordingVerifyMessage_1[props.lang]}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkedState[1]} onChange={(event) => handleCheckboxChange(event, 1)}
                                sx={{'& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                            label={Tabstext.recordingVerifyMessage_2[props.lang]}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkedState[2]} onChange={(event) => handleCheckboxChange(event, 2)}
                                sx={{'& .MuiSvgIcon-root': { fontSize: 20 }}}  />}
                            label={Tabstext.recordingVerifyMessage_3[props.lang]}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ContainedButton onClick={() => handleVerify(1)} disabled={!okEnabled} >
                            {Tabstext.MyRecordingGood[props.lang]}
                        </ContainedButton>
                        <ContainedButton2 onClick={() => handleVerify(-1)} >
                            {Tabstext.PlayAgain[props.lang]}
                        </ContainedButton2>
                        <ContainedButton3 onClick={() => handleVerify(0)} >
                            {Tabstext.MyRecordingBad[props.lang]}
                        </ContainedButton3>
                    </div>
                </div>
            </Popover>
            <ScorePage context={context} lang={props.lang}
                exe={exe} musicScore={musicScore} feedbackData={feedbackData}
                feedbackDisplay={feedbackDisplay} setFeedbackDisplayCallback={setFeedbackDisplayCallback}
                windowSize={props.windowSize}
                handlePlaybackEndCallback={handlePlaybackEndCallback}
            />
        </ExerciseContainer>
    )
}

export default RecordingTest;

