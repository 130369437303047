import React from 'react'
import Tabstext from './tabstext';
import Introtext from './introtext';
import { Button, Typography } from '@mui/material';
import { Dialog, DialogContent, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PointsDialog(props) {
    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';

    return (
        <Dialog
            open={props.showDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.closeDialog}
            maxWidth='70%'
            dir={lang_dir}
            >
            <DialogContent>
                <div className='paleYellowBg' style={{ display: 'flex', flex: 1, flexDirection: 'column', }}>
                    <div style={{ padding: '10px' }}>
                        <Typography variant='h5' className='blue' >
                            {Introtext.SongsAndSolfege[props.lang]}
                        </Typography>
                    </div>
                    <div style={{ padding: '4px' }}>
                        <Typography variant='h6' >
                            <img src='/static/icons/bird1.svg' alt="Image" width="25" height="25" style={{ margin:'0 10px' }} />
                            {Introtext.PerformanceScore_0[props.lang]}
                        </Typography>
                    </div>
                    <div style={{ padding: '4px' }}>
                        <Typography variant='h6' >
                            <img src='/static/icons/bird1.svg' alt="Image" width="25" height="25" style={{ margin:'0 10px' }} />
                            {Introtext.PerformanceScore_1[props.lang]}
                        </Typography>
                    </div>
                    <div style={{ padding: '4px' }}>
                        <Typography variant='h6' >
                            <img src='/static/icons/bird1.svg' alt="Image" width="25" height="25" style={{ margin:'0 10px' }} />
                            {Introtext.PerformanceScore_1a[props.lang]}
                        </Typography>
                    </div>
                    <div style={{ padding: '4px' }}>
                        <Typography variant='h6' >
                            <img src='/static/icons/bird1.svg' alt="Image" width="25" height="25" style={{ margin:'0 10px' }} />
                            {Introtext.PerformanceScore_2[props.lang]}
                        </Typography>
                    </div>
                    <div style={{ padding: '4px' }}>
                        <Typography variant='h6' >
                            <img src='/static/icons/bird1.svg' alt="Image" width="25" height="25" style={{ margin:'0 10px' }} />
                            {Introtext.PerformanceScore_3[props.lang]}
                        </Typography>
                    </div>
                    <div style={{ padding: '4px' }}>
                        <Typography variant='h6' >
                            <img src='/static/icons/bird1.svg' alt="Image" width="25" height="25" style={{ margin:'0 10px' }} />
                            {Introtext.PerformanceScore_4[props.lang]}
                        </Typography>
                    </div>
                    <div style={{ padding: '10px' }}>
                        <Typography variant='h5' className='blue' >
                            {Tabstext.SolfegeTab[props.lang]}
                        </Typography>
                    </div>
                    <div style={{ padding: '4px' }}>
                        <Typography variant='h6' >
                            <img src='/static/icons/bird1.svg' alt="Image" width="25" height="25" style={{ margin:'0 10px' }} />
                            {Introtext.PerformanceScore_5[props.lang]}
                        </Typography>
                    </div>
                    <div style={{ padding: '4px' }}>
                        <Typography variant='h6' >
                            <img src='/static/icons/bird1.svg' alt="Image" width="25" height="25" style={{ margin:'0 10px' }} />
                            {Introtext.PerformanceScore_6[props.lang]}
                        </Typography>
                    </div>
                    <Button
                        onClick={props.closeDialog}
                        style={{ position: 'absolute', top: 0, [lang_dir === 'rtl' ? 'left' : 'right']: 0, marginTop: '10px' }}
                    >
                    <CloseIcon style={{ fontSize: '16px', fontWeight: 500, color: 'black' }} />
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default PointsDialog;

