import React from 'react';

function Fragment({ fragment, onDragStart }) {
  return (
    <img
      src={`/media/games/puzzle-sound/${fragment}`}
      alt={fragment}
      draggable
      onDragStart={(e) => onDragStart && onDragStart(e, fragment)}
      className="fragment"
    />
  );
}

export default Fragment;
