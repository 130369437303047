import React from 'react';
import Fragment from './Fragment';

function Stash({ stash, onDropFragment }) {
  const onDragStart = (e, fragment) => {
    e.dataTransfer.setData('fragment', fragment); // Store the fragment being dragged
  };

  const onDrop = (e) => {
    e.preventDefault(); // Prevent default behavior
    const fragment = e.dataTransfer.getData('fragment');
    onDropFragment(fragment); // Call the handler to move fragment to the stash
  };

  const onDragOver = (e) => {
    e.preventDefault(); // Allow fragment to be dropped into the stash
  };

  return (
    <div className="stash" onDrop={onDrop} onDragOver={onDragOver}>
      <h3></h3>
      {stash.map((fragment, index) => (
        <Fragment key={index} fragment={fragment} onDragStart={onDragStart} />
      ))}
    </div>
  );
}

export default Stash;
