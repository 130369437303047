import React, { useState, useEffect } from 'react';
import { axiosInstance, setupAxiosInterceptors } from './axios_instance';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import PublicPage from './public_page';
import LoginForm from './login';
import RegisterForm from './register';
import RecordingForGuest from './recording_for_guest';
import Home from './home';
import SessionProvider from './SessionContext';
import Tabstext from './tabstext';

function App() {
    const [headerContext, setContext] = useState(null);
    const userLang = setUserLanguage();
    const [lang, setLang] = useState(userLang);
    const [authenticated, setAuthenticated] = useState(null);
    const [loading, setLoading] = useState(true); // New loading state
    const navigate = useNavigate();
    const location = useLocation();
    const langChangeCallback = (lang) => {
        setLang(lang);
    };

    const setAuthenticatedCallback = (val) => {
        setAuthenticated(val);
    };

    useEffect(() => {
        setupAxiosInterceptors(() => window.location.href = '/');
    }, [navigate]);

    useEffect(() => {
        axiosInstance.get('/api/authenticate/')
            .then((response) => {
                setAuthenticated(response.data);
            })
            .catch(() => {
                setAuthenticated(false);
            });
    }, []);

    useEffect(() => {
        if (authenticated !== null) {
            axiosInstance.get('/api/header/', { params: {} })
                .then((response) => {
                    setContext(response.data.context);
                });
        }
    }, [authenticated]);

    useEffect(() => {
        if (headerContext) {
            if (headerContext.authenticated) {
                if (location.pathname !== '/app/')
                    navigate('/app/');
            } else {
                if (headerContext && window.groupid !== 'None' && headerContext.allGroupIds.includes(parseInt(window.groupid))) {
                    //document.exitFullscreen();
                    navigate('/register/' + window.groupid);
                } else {
                    //document.exitFullscreen();
                    navigate('/');
                }
            }
            setLoading(false); // Set loading to false after explicit navigate
        }
    }, [headerContext]);

    // Redirect to public page if not authenticated
    useEffect(() => {
        if (!authenticated && location.pathname === '/app/') {
            //document.exitFullscreen();
            navigate('/');
        }
    }, [authenticated, location.pathname]);

    const [SolfegeLevels, setLevels] = useState([]);
    const [SongClasses, setClasses] = useState([]);
    useEffect(() => {
        axiosInstance.get('/api/contents/').then((response) => {
            setLevels(response.data.SolfegeLevels);
            setClasses(response.data.SongClasses);
        });
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Render loading state
    }

    return (
        <div id="appContainer" style={{ height: '100%' }}>
            <SessionProvider
                setAuthenticatedCallback={setAuthenticatedCallback}
                sessionTimeout={headerContext.session_timeout_sec}
                expiredText={Tabstext.SessionExpired[lang]}
            >
                <Routes>
                    <Route
                        path="/"
                        element={
                            <PublicPage
                                lang={lang}
                                authenticated={authenticated}
                                headerContext={headerContext}
                                langChangeCallback={langChangeCallback}
                                SolfegeLevels={SolfegeLevels}
                                SongClasses={SongClasses}
                            />
                        }
                    />
                    <Route
                        path="/app/*"
                        element={
                            <Home
                                authenticated={authenticated}
                                headerContext={headerContext}
                                langChangeCallback={langChangeCallback}
                                lang={lang}
                                setAuthenticatedCallback={setAuthenticatedCallback}
                                SolfegeLevels={SolfegeLevels}
                                SongClasses={SongClasses}
                            />
                        }
                    />
                    <Route
                        path="/login/"
                        element={
                            <LoginForm
                                setAuthenticatedCallback={setAuthenticatedCallback}
                                lang={lang}
                                RecaptchaPubKey={headerContext?.RecaptchaPubKey}
                            />
                        }
                    />
                    <Route
                        path="/register/*"
                        element={
                            <RegisterForm
                                lang={lang}
                                authenticated={authenticated}
                                setAuthenticatedCallback={setAuthenticatedCallback}
                                allGroupIds={headerContext?.allGroupIds}
                            />
                        }
                    />
                    <Route
                        path="/recording/*"
                        element={
                            <RecordingForGuest
                                lang={lang}
                                authenticated={authenticated}
                                headerContext={headerContext}
                            />
                        }
                    />
                </Routes>
            </SessionProvider>
        </div>
    );
}

export default App;

function setUserLanguage() {
    const browserLanguage = navigator.language || navigator.userLanguage;
    let language;
    if (browserLanguage.includes('ro')) language = 'ro';
    else if (browserLanguage.includes('he')) language = 'he';
    else language = 'en';
    return language;
}
