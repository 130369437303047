import React from 'react'
import { useState, useEffect } from "react";
import Tabstext from './tabstext';
import {filterChars} from './exercise_utils';
import { SolfyTreeView, FirstTreeItem, SecondTreeItem, } from './customized_components'
import { Button, Tooltip, Typography} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import FastForwardIcon from '@mui/icons-material/FastForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './styles/extras.css';
import './styles/colors.scss';
import { CustomTypography } from './styled_components';


function Sidebar(props) {
    const SolfegeLevels = props.SolfegeLevels;
    const SongClasses = props.SongClasses;
    const [lyricsMode, setLyricsMode] = useState(props.lyricsMode);
//    const expandedLeaf = props.exe.song==null ? props.exe : props.exe.song
    const defaultExpandedNodes = [String(props.exe.course_pk), String(props.exe.lesson_pk + props.exe.course_pk*100)];

    useEffect(() => {
        setLyricsMode(props.lyricsMode);
    }, [props.lyricsMode]); // Add props.pk as a dependency

    if (SolfegeLevels === null) {
        return null;
    }
    return (
        <div className='sidebar' >
            {lyricsMode === 'solfege' &&
                <SolfegeTree SolfegeLevels={SolfegeLevels} lang={props.lang}
                    defaultExpandedNodes = {defaultExpandedNodes}
                    exe={props.exe} setPkCallback={props.setPkCallback}
                    firstUnpassedLesson = {props.firstUnpassedLesson}
                    firstUnpassedLevel = {props.firstUnpassedLevel}
                    firstIncompleteExercise={props.firstIncompleteExercise}
                    firstIncompleteExeLastLesson={props.firstIncompleteExeLastLesson}
                    lastLessonInLevel={props.lastLessonInLevel}
                    firstExerciseLastLesson={props.firstExerciseLastLesson}
                    asStudent={props.asStudent}
                    SolfegeLevels={props.SolfegeLevels}
                    solfegesPassStatus={props.solfegesPassStatus}
                    />
            }
            {lyricsMode !== 'solfege' &&
                <SongsTree SongClasses={SongClasses} lang={props.lang}
                    defaultExpandedNodes = {defaultExpandedNodes}
                    exe={props.exe} setPkCallback={props.setPkCallback}
                    user_id={props.user_id} user_group_id={props.user_group_id}
                    />
            }
        </div>
    );
}

function SolfegeTree (props) {
    const SolfegeLevels = props.SolfegeLevels;
    const asStudent = props.asStudent;
    const firstUnpassedLevel = props.firstUnpassedLevel;
    const firstUnpassedLesson = props.firstUnpassedLesson;
    const firstIncompleteExercise = props.firstIncompleteExercise
    const firstIncompleteExeLastLesson = props.firstIncompleteExeLastLesson
    const lastLessonInLevel = props.lastLessonInLevel;
    const firstExerciseLastLesson = props.firstExerciseLastLesson;

    const [expanded, setExpanded] = useState(props.defaultExpandedNodes);
    const handleToggle = (event, itemIds) => {
        setExpanded(itemIds);
    };

    const [lock, setLock] = useState(null);

    useEffect(() => {
        setLock(props.asStudent);
    }, [props.asStudent]); // Add props.pk as a dependency

    useEffect(() => {
        setExpanded(props.defaultExpandedNodes);
    }, [props.defaultExpandedNodes]); // Add props.pk as a dependency

//    document.body.dir = props.lang=='he' ? 'rtl' : 'ltr';

    return (
        <div >
            <SolfyTreeView
                expandedItems={expanded}
                onExpandedItemsChange={handleToggle}
                >
                {SolfegeLevels.map((course, i) => (
                    <FirstTreeItem itemId={String(course.pk)} key={`${i}a`}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center', gap:'12px' }}>
                                {filterChars(course.title, props.lang)}
                                {asStudent && course.serial_number > firstUnpassedLevel ? <LockIcon style={{fontSize:'16px'}} /> : null}
                            </div>
                        }
                        disabled = {asStudent && course.serial_number>firstUnpassedLevel}
                    >
                    {course.lesson_set.map((lesson, j) => (
                        <Tooltip title={lesson.serial_number === lastLessonInLevel ?Tabstext.LastLessonTooltip[props.lang] : undefined}
                            placement='top' key={`${i * 100 + j}d`}>
                            <div>
                                {lesson.serial_number === lastLessonInLevel &&
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                        <Typography >{Tabstext.FastTrackTab[props.lang]}{filterChars(SolfegeLevels[i + 1].title, props.lang)}</Typography>
                                        <FastForwardIcon style={{ fontSize: '16px' }} />
                                    </div>
                                }
                                <div>
                                    <SecondTreeItem itemId={String(course.pk*100+lesson.pk)} key={`${i*100+j}d`}
                                        label={lesson.title}
                                        disabled = {asStudent && lesson.serial_number>firstUnpassedLesson && lesson.serial_number!=lastLessonInLevel}
                                        icon = {asStudent && lesson.serial_number>firstUnpassedLesson && lesson.serial_number!=lastLessonInLevel ? <LockIcon /> : null}
                                        style={{ color: lesson.pk == props.exe.lesson_pk ? 'var(--dark-orange)' : 'var(--blue-5)', }}
                                        >
                                    {lesson.exercise_set.map((exe, k) => (
                                        <Button id={String(course.pk*100+lesson.pk*1000+exe.pk)}  key={`${i*100+j*1000+k}e`}
                                            disabled = {asStudent && exe.serial_number>firstIncompleteExercise &&
                                                (exe.serial_number>firstIncompleteExeLastLesson || exe.serial_number<firstExerciseLastLesson)}
                                            onClick={() => props.setPkCallback(exe.pk)}
                                            style={{
                                                color: 'var(--blue-5)', backgroundColor: exe.pk == props.exe.pk ? 'white' : 'transparent',
                                                 display:'block', width:'100%', borderRadius:'24px',height:'35px',
                                               }}
                                            >
                                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', flex:1, width:'100%'}} >
                                                <p className="ellipsis-text" style={{ textTransform: 'none', fontSize:'13px', fontWeight:'550'}}>
                                                    {exe.title}
                                                </p>
                                                {props.solfegesPassStatus && props.solfegesPassStatus[exe.pk] &&
                                                    <CheckCircleIcon style={{ fontSize: '18px', color: 'var(--blue-4)' }}/>
                                                    }
                                                {props.solfegesPassStatus && !props.solfegesPassStatus[exe.pk] &&
                                                    <CheckCircleOutlineIcon style={{fontSize: '18px', color:'var(--blue-3)'}}/>
                                                    }
                                            </div>
                                        </Button>
                                    ))}
                                    </SecondTreeItem>
                                </div>
                            </div>
                        </Tooltip>
                    ))}
                  </FirstTreeItem>
                ))}
            </SolfyTreeView>
        </div>
    );
}

function SongsTree (props) {
    const SongClasses = props.SongClasses;
    const [expanded, setExpanded] = useState(props.defaultExpandedNodes);
    const handleToggle = (event, itemIds) => {
        setExpanded(itemIds);
    };

    useEffect(() => {
        setExpanded(props.defaultExpandedNodes);
    }, [props.defaultExpandedNodes]); // Add props.pk as a dependency

//    document.body.dir = props.lang=='he' ? 'rtl' : 'ltr';

    return (
        <div>
            <SolfyTreeView
                aria-label="file system navigator"
                expandedItems={expanded}
                onExpandedItemsChange={handleToggle}
                >
                {SongClasses.map((course, i) => (
                  <FirstTreeItem itemId={String(course.pk)} key={`${i}a`}
                    label={filterChars(course.title,props.lang)}
                    >
                     {course.lesson_set.map((lesson, j) => (
                        <SecondTreeItem  itemId={String(course.pk*100+lesson.pk)} key={`${i*100+j}d`}
                            label={lesson.title}
                             style={{ color: lesson.pk == props.exe.lesson_pk ? 'var(--dark-orange)' : 'var(--blue-5)' }}
                            >
                            {lesson.songlesson_set.map((song, k) => (
                                <SongButton id={String(course.pk * 100 + lesson.pk * 1000 + song.pk)} key={`${i * 100 + j * 1000 + k}e`}
                                    song={song} exe={props.exe}
                                    user_id={props.user_id} user_group_id={props.user_group_id}
                                    setPkCallback={props.setPkCallback}
                                />
                            ))}
                        </SecondTreeItem>
                    ))}
                  </FirstTreeItem>
                ))}
            </SolfyTreeView>
        </div>
    );
}

function SongButton(props) {
    const song = props.song;
    const enabled = (song.unlocked_for_user_ids.length == 0 && song.unlocked_for_group_ids.length == 0) ||
        song.unlocked_for_user_ids.includes(props.user_id) || song.unlocked_for_group_ids.includes(props.user_group_id);

    return (
        <Button
            onClick={() => props.setPkCallback(song.first_exercise.pk)}
            style={{
                color: 'var(--blue-5)',
                backgroundColor: song.ids_set.includes(props.exe.pk) ? 'white' : 'transparent',
                display: 'flex', justifyContent: 'left', borderRadius: '24px', height: '35px',
            }}
            disabled={!enabled}
            >
            <CustomTypography
                normalstyle={{ textTransform: 'none', fontWeight: '550', fontSize: '13px' }}
                smallstyle={{ textTransform: 'none', fontWeight: '450', fontSize: '11px' }}
                addclass="ellipsis-text"
                text={song.title}
                disabled={!enabled}
            />
        </Button>
    )
}


export default Sidebar;