import React from 'react'

const Introtext = {
    cultivating : {
    'en': 'Cultivating Music Growth through Singing',
    'he': 'טיפוח התפתחות מוזיקלית דרך שירה',
    'ro': 'Cultivarea Aptitudinilor Muzicale prin Cânt Vocal',
    },
    SolfyForTeachers : {
    'en': 'Solfy for Teachers',
    'he': 'סולפי למורים',
    'ro': 'Solfy pentru profesori',
    },
    TeacherPromo : {
    'en': 'Encourage singing while fostering music literacy and social emotional learning, optimize lesson creation and resource allocation, focus on more personalized instruction, integrate in-class and at-home learning experiences, and track your pupils individual achievements',
    'he': 'עודדו שירה תוך טיפוח אוריינות מוזיקלית ולמידה רגשית חברתית, מצאו כאן אוסף מגוון של חומרי לימוד, הקדישו יותר להנחייה אישית, שלבו חוויות למידה בכיתה ובבית, ועקבו אחר ההישגים האישיים של תלמידיכם',
    'ro': 'Încurajați cântul vocal, stimulând alfabetizarea muzicală și învățarea socio-emoțională, optimizați planificarea lecțiilor și alocarea resurselor, focalizați pe instruire personalizată, integrați experiențele de învățare din clasă cu cele de acasă, și monitorizați și coordonați realizările individuale ale elevilor dvs',
    },
    TeacherPromo1 : {
    'en': 'Optimize lesson creation and resource allocation',
    'he': 'תמצאו כאן אוסף מגוון של חומרי לימוד',
    'ro': 'Optimizați planificarea lecțiilor și alocarea resurselor',
    },
    TeacherPromo2 : {
    'en': 'Focus on more personalized instruction',
    'he': 'תוכלו להקדיש יותר להנחייה אישית',
    'ro': 'Focalizați pe instruire personalizată',
    },
    TeacherPromo3 : {
    'en': 'Encourage singing while fostering music literacy and social emotional learning (SEL)',
    'he': 'עודדו שירה תוך טיפוח אוריינות מוזיקלית ולמידה רגשית חברתית',
    'ro': 'Încurajați cântul vocal, stimulând alfabetizarea muzicală și învățarea socio-emoțională (ISE)',
    },
    TeacherPromo4 : {
    'en': 'Integrate in-class and at-home learning experiences',
    'he': 'שלבו חוויות למידה בכיתה ובבית',
    'ro': 'Integrați experiențele de învățare din clasă cu cele de acasă',
    },
    TeacherPromo5 : {
    'en': 'Track your pupils individual achievements',
    'he': 'עקבו אחר ההישגים האישיים של תלמידיכם',
    'ro': 'Monitorizați și coordonați realizările individuale ale elevilor dvs.',
    },
    SolfyForStudents : {
    'en': 'Solfy for Students',
    'he': 'סולפי לתלמידים',
    'ro': 'Solfy pentru elevi',
    },
    StudentPromo : {
    'en': 'Improve your singing by receiving a constructive feedback, enjoy listening to your own singing with a delightful accompaniment, and develop your music literacy',
    'he': 'לימדו לשיר טוב יותר בעזרת משוב חכם, שמעו את עצמכם שרים עם ליווי עשיר ומהנה, והכירו את שפת המוזיקה והתווים ',
    'ro': 'Dobândiți calități vocale superioare primind feedback constructiv, bucurați-vă ascultând înregistrările proprii cu acompaniamente captivante, și aprofundați alfabetizarea muzicală',
    },
    StudentPromo1 : {
    'en': 'Improve your singing by receiving a constructive feedback',
    'he': 'לימדו לשיר טוב יותר בעזרת משוב חכם',
    'ro': 'Dobândiți calități vocale superioare primind feedback constructiv',
    },
    StudentPromo2 : {
    'en': 'Practice singing with audio and visual guides',
    'he': 'התאמנו לשיר עם הנחייה קולית וחזותית ',
    'ro': 'Practicați cântul cu ghiduri audio și vizuale',
    },
    StudentPromo3 : {
    'en': 'Develop your music literacy',
    'he': 'הכירו את שפת המוזיקה והתווים',
    'ro': 'Aprofundați alfabetizarea muzicală',
    },
    StudentPromo4 : {
    'en': 'Enjoy listening to your own singing with a delightful accompaniment',
    'he': 'שמעו את עצמכם שרים עם ליווי עשיר ומהנה',
    'ro': 'Bucurați-vă ascultând înregistrările proprii cu acompaniamente captivante',
    },
    StudentPromo5 : {
    'en': 'Share your shining recordings with family and friends',
    'he': 'שתפו את ההקלטות המרגשות שלכם עם משפחה וחברים',
    'ro': 'Partajați înregistrările de succes cu familia și prietenii',
    },
    AboutUs_1 : {
   'en': "We are a small team, dedicated to raising the level of music education in public schools. The team includes Dr. Morel Koren, a musician and music educator, Dr. Adoram Erell, an expert in computerized voice analysis and software development, and Tzipi Koren, who brings expertise in music-teaching for the young ones.  Many of the accompaniments on this website were prepared by the composers Michael Dulitsky (Ukraine, Israel), Bogdan Focșăneanu (Romania, Canada), and Inon Tzur (Israel, USA). Dr. Adina Portovitz from the Washington Hill College, Israel, Dr. Sarah Weidenfeld from the Jerusalem College, and Professor Eldad Tsabary from Concordia University, Montreal, Canada, contributed to this project. An initial pilot study in Romania had been assisted by the music teachers Ciprian Juncă and Ilie Hrubaru from Iași, Dr. Lordana Muntean from the University of Oradea, Dr. Ioan Chiciudean, from High School of Arts “Queen Mary“ Alba Iulia, Dr. Diana Sârb from “George Dima“ Academy of Music, Cluj, Ms. Maria Popescu, a former music inspector of Iași County, and Prof. Viorel Munteanu, a former Rector of the “George Enescu” University of Arts in Iași. The songs from the George Breazul collection vol. 1, were recorded by the Aletheia Choir, conductor Dorina Comănescu.",
   'he': "אנחנו צוות קטן, מסור להעלאת הרמה של הוראת המוזיקה במערכת החינוך הכללי. הצוות כולל את ד\"ר מורל קורן, מוזיקאי ומחנך, ד\"ר אדורם אראל, מומחה בתחום ניתוח קול ממוחשב ופיתוח תוכנה, וציפי קורן, המביאה מומחיות וניסיון בהוראת מוזיקה לילדים. חלק ניכר מהעיבודים המוזיקליים באתר זה הוכנו על ידי המלחינים מיכאל דוליצקי (אוקראינה, ישראל), בוגדן פוקסנאו (רומניה, קנדה) וינון צור (ישראל, ארה\"ב). ד\"ר עדינה פורטוביץ מקרית חינוך גבעת ושינגטון, ישראל, ד\"ר שרה ווידנפלד ממכללת ירושלים, ופרופ' אלדד צברי מאוניברסיטת קונקורדיה, קנדה, תרמו לפרוייקט. ניסוי מקדים ברומניה נעזר במורים ציפריאן ז'ונקה ואיליה חרוברו מיאשי, ד\"ר לורדנה מונטאן מאוניברסיטת אורדאה, ד\"ר יוהן קיצ'יודאן מבית הספר הגבוה לאמנויות \"המלכה מריה\", ד\"ר דיאנה שרב מהאקדמיה למוזיקה על שם \"ג'ורג' דימה\" בקלוז', גב' מריה פופסקו, בעבר מפקחת הוראת מוזיקה במחוז יאשי, ופרופ' וייוראל מונטנאו, בעבר רקטור של האקדמיה לאמנויות על שם \"ג'ורג' אנסקו\", יאשי.",
   'ro': 'Suntem o echipă mică, dedicată a contribui la creșterea nivelului de educație muzicală în școlile publice. Din echipă fac parte: Dr. Morel Koren, profesor de muzică, Dr. Adoram Erell, expert în analiza computerizată a vocii și dezvoltarea software, și Tzipi Koren, cu experiență în predarea muzicii pentru începători. Acompaniamentele muzicale au fost pregătite de compozitorii Michael Dulitsky (Ucraina, Israel), Bogdan Focșăneanu (România, Canada) și Inon Tzur (Israel, SUA). Dr. Adina Portovitz de la Washington Hill College, Israel, dr. Sarah Weidenfeld de la Jerusalem College și profesorul Eldad Tsabary de la Universitatea Concordia, Montreal, Canada, au contribuit la acest proiect. Un studiu pilot inițial în România a fost asistat de profesorii de muzică Ciprian Juncă și Ilie Hrubaru din Iași, Dr. Lordana Muntean de la Universitatea din Oradea, Dr. Ioan Chiciudean, de la Liceul de Arte „Regina Maria” Alba Iulia, Dr. Diana Sârb de la Academia de Muzică “George Dima“ Cluj, d-na Maria Popescu, fost inspector muzical al județului Iași, și prof. Viorel Munteanu, fost rector al Universității de Arte „George Enescu” din Iași. Cântecele din colecția George Breazul vol. 1, au fost înregistrate de Corul Aletheia, dirijor Dorina Comănescu.',
    },
    AboutUs_2 : {
   'en': "",
   'he': "  תכנית לימודים מבוססת סולפי נמצאת בגפ\"ן כתוכנית מספר 31556 \"שילוב אוריינות מוזיקלית בכיתה (תרגול שירה ואוריינות מוזיקלית בבית התלמיד עם לומדה אינטראקטיבית.)\" ",
   'ro': '',
   },
   AboutUs_3 : {
   'en': "",
   'he': "קישור לסולפי בגפ\"ן",
   'ro': '',
   },
   SongsAndSolfege : {
   'en': "Songs and Solfeges",
   'he': "שירים וסולפג'ים",
   'ro': 'Cântece și Solfegii',
   },
   ViewPracticeDemo : {
   'en': "View a Practicing Demo",
   'he': "צפו בהדגמה של תירגול",
   'ro': 'Vizualizați o demonstrație de practică',
   },
   ViewPracticeDemo2 : {
   'en': "Experience the singing and the feedback firsthand",
   'he': "התרשמו מתירגול השירה והמשוב",
   'ro': 'Exemplu de înregistrare și feedback',
   },
    PerformanceScore_0 : {
   'en': 'An "all green" feedback earns 100 points.',
   'he': "משוב שכולו ירוק מזכה ב 100 נקודות",
   'ro': 'Un feedback „complet verde” adaugă 100 de puncte.',
   },
    PerformanceScore_1 : {
   'en': "A deviation in pitch or timing, indicated by a red note, deduces 10 points.",
   'he': 'סטייה בגובה הצליל או התיזמון שלו, המצויינת ע"י תו אדום, מורידה 10 נקודות',
   'ro': 'O deviație de înălțime sau durată, marcată cu roșu, deduce 10 puncte.',
   },
    PerformanceScore_1a : {
   'en': 'A discrepancy in dynamics, indicated by a red "f" or "p", deduces 10 points.',
   'he': 'סטייה בדינמיקה, המצויינת באדום, מורידה 10 נקודות',
   'ro': 'O discrepanță în dinamică, indicată de un „f” sau „p” roșu, deduce 10 puncte.',
   },
    PerformanceScore_2 : {
   'en': "Stepping up the tempo gains 10 more points.",
   'he': 'עליית מדרגה בקצב מוסיפה 10 נקודות.',
   'ro': 'Utilizarea unui tempo mai rapid adaugă 10 puncte.',
   },
    PerformanceScore_3 : {
   'en': "Stepping down the out-of-tune tolerance gains 10 more points.",
   'he': 'הקטנת הסטייה המותרת בגובה הצליל, במדרגה אחת, מוסיפה 10 נקודות.',
   'ro': 'Reducerea toleranței inițiale, adaugă 10 puncte.',
   },
    PerformanceScore_4 : {
   'en': "Score above 80 earns a delightful accompaniment.",
   'he': 'ציון מעל 80 מזכה בליווי מהנה',
   'ro': 'Un punctaj de 80, sau mai mare, recompensează cu un acompaniament captivant.',
   },
   PerformanceScore_5 : {
   'en': "A discrepancy in note name, marked in red, reduces 10 points.",
   'he': 'טעות בשם התו, המצויינת באדום, מורידה 10 נקודות.',
   'ro': 'O discrepanță în numele notei, marcată cu roșu, deduce 10 puncte.',
   },
   PerformanceScore_6 : {
   'en': "The minimum score for passing to the next Solfege exercise is 60.",
   'he': 'הציון המינימלי להתקדמות לתרגיל הבא הוא 60',
   'ro': 'Punctajul minim pentru trecerea la următorul exercițiu de Solfegiere este 60.',
   },
   PerformanceScore_7 : {
   'en': "Passing all 4 exercises of a lesson earns a colorful musical instrument icon.",
   'he': 'מעבר של כל 4 התרגילים בשיעור, מזכה באייקון של כלי נגינה צבעוני',
   'ro': 'Parcurgerea celor 4 exerciții ale unei lecții este recompensată cu o pictogramă de instrument muzical colorat.',
   },

};

export default Introtext;