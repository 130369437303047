import React from 'react'
import { useState, useRef, useEffect} from "react";
import { useNavigate, } from 'react-router-dom';
import LangSelect from './lang_select';
import PublicPromo from './public_promo';
import PracticeDemo from './practice_demo';
import SongsContent from './songs_content';
import Tabstext from './tabstext';
import Introtext from './introtext';
import ContactUs from './contact_us';
import PdfViewer from './pdf_viewer_iframe';
import { render_lyrics_docx, } from './exercise_utils';
import { ContainedButton2, LinkButton, LinkButtonTab, SmallTitle } from './customized_components';
import { ReactComponent as SolfyLogo } from '/static/icons/solfyLogo.svg';
import { Button, Typography, Tabs, } from '@mui/material';
import './styles/index.scss';


function PublicPage(props) {
    const navigate = useNavigate();
    const docxRef = useRef(null);

    const [value, setValue] = useState('home'); // State to manage active tab
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const solfegePicSrc = props.lang=='he' ? "/static/art/Solfege_IL.jpg" : (props.lang=='en' ? "/static/art/Solfege_EN.jpg": "/static/art/Solfege_RO.jpg" );
    const practicePicSrc = props.lang == 'he' ? "/static/excerpts/eretz_he.png" : (props.lang == 'en' ? "/static/excerpts/unElefant_en.png" : "/static/excerpts/unElefant_ro.png" );

    const tutorial_file_url = props.lang == 'he' ?
        '/static/tutorials/סולפי_הדרכה.pdf' :
        '/static/tutorials/Tradition_and_modernity_in_music_education_2.1.pdf';

    //useEffect(() => {
    //    if (docxRef.current)
    //        render_lyrics_docx(tutorial_file_url, docxRef.current);
    //}, [docxRef,props.lang]);

    const lang_dir = props.lang == 'he' ? 'rtl' : 'ltr';
    const displayTutorial = value == 'tutorial' ? '' : 'none';

    const gefenLink = 'https://apps.education.gov.il/tyhnet/public/#/tochniyot?q=31556';

    return (
        <div style={{ display: 'flex', flexDirection: 'column', }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'sticky', backgroundColor:'white', top: 0, zIndex: 1, }}>
                <Button onClick={() => setValue('home')} >
                    <SolfyLogo style={{marginLeft:'20px'}} />
                </Button>
                <Tabs value={value} onChange={handleChange} orientation="horizontal">
                    <LinkButtonTab value="home" label={Tabstext.HomeTab[props.lang]}  />
                    <LinkButtonTab value="solfeges" label ={Tabstext.SolfegeContentTab[props.lang]} />
                    <LinkButtonTab value="songs" label ={Tabstext.SongsContentTab[props.lang]} />
                    <LinkButtonTab value="tutorial" label={Tabstext.TutorialTab[props.lang]} />
                    <LinkButtonTab value="about_us" label ={Tabstext.AboutUsTab[props.lang]}  />
                    <LinkButtonTab value="contact_us" label={Tabstext.ContactUsTab[props.lang]} />
                </Tabs>
                <div style={{display:'flex'}} >
                    <LinkButton onClick={() => navigate(`/login`)} >
                        {Tabstext.SignInTab[props.lang]}
                    </LinkButton>
                    <LangSelect
                        context={props.headerContext}
                        lang={props.lang} langChangeCallback={props.langChangeCallback}
                    />
                </div>
            </div>
            {value=='home' &&
            <>
                <div className="paleBlueBg" style={{ display: 'flex' }} >
                    <div dir={lang_dir} style={{ width: '50%', margin: '60px', display:'flex', flexDirection:'column', gap:'40px'}}>
                        <div >
                            <Typography variant="h1" className='blue' gutterBottom >
                                {Introtext.cultivating[props.lang]}
                            </Typography>
                            <ContainedButton2 size="large" onClick={() => navigate(`/register`)} >
                                {Tabstext.GetStartedTab[props.lang]}
                            </ContainedButton2>
                            <LinkButton size="large" className='solfyBtn solfyBtnPrimary'
                                onClick={() => navigate(`/login`)} >
                                {Tabstext.RegisteredProceed[props.lang]}
                            </LinkButton>
                        </div>
                        <div style={{ display: props.lang == 'he' ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center' }} >
                            <LinkButton size="large" className='solfyBtn solfyBtnPrimary'
                                href={gefenLink}
                                target="_blank" rel="noopener noreferrer" >
                                {'סולפי בגפ"ן'}
                            </LinkButton>
                        </div>
                    </div>
                    <div style={{ width: '50%', height: '80%', marginTop: '60px', marginLeft: '90px', marginBottom: '50px', display: 'flex', justifyContent: 'right' }}>
                        <div className='imageDiv' style={{width: '620px', height: '350px',}}>
                            <img src={practicePicSrc} height='100%' />
                        </div>
                    </div>
                </div>
                <PublicPromo lang={props.lang} />
                <PracticeDemo lang={props.lang} />
            </>
            }
            {value=='solfeges' &&
                <div  style={{ display: 'flex', flex:1, alignItems:'center', justifyContent:'center', padding:'10px'  }} >
                    <img src={solfegePicSrc} style={{maxWidth: '100%',maxHeight: '100%', }} />
                </div>
            }
            {value=='songs' &&
                <SongsContent
                    lang={props.lang}
                    SongClasses={props.SongClasses}
                    headerContext={props.headerContext}
                    />}
            {value=='about_us' &&
                <div className='creamBgrd' style={{ display: 'flex', flex:1, flexDirection: 'column' }} >
                    <Typography variant='body1' dir={lang_dir} >
                        {Introtext.AboutUs_1[props.lang]}
                    </Typography>
                </div>
            }
            {value == 'contact_us' && <ContactUs lang={props.lang} />}
            <div style={{ display: displayTutorial }}>
                <PdfViewer pdfFileName={tutorial_file_url} />
            </div>
        </div>
    );
}



export default PublicPage;

//<div id="docx-container"
//    ref={docxRef} dir={lang_dir}
//    style={{ display: displayTutorial }}>
//</div>
